const { map } = require('jquery');
require('jquery');
const PerfectScrollbar = require('perfect-scrollbar');
const WOW = require('wowjs');
require('inputmask');

$(document).ready(function () {
    new WOW.WOW().init();
    scrollBarInit();
});

function scrollBarInit() {
    $('#scrollElement').length && new PerfectScrollbar('#scrollElement');
}

/* Добавляем год который сейчас в footer */
let date = new Date();
let year = date.getFullYear();
let dateDiv = document.querySelector(".dateNow");

dateDiv.innerHTML = year;

/* SLICK SLIDER */

$('.favorites-container').slick({
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    prevArrow: $('.favorites-prev'),
    nextArrow: $('.favorites-next'),
    responsive: [
        {
            breakpoint: 769,
            settings: {
                dots: true
            }
        },
    ]
})

$('.reviews-container').slick({
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    prevArrow: $('.reviews-prev'),
    nextArrow: $('.reviews-next'),
    responsive: [
        {
            breakpoint: 992,
            settings: {
                dots: true
            }
        },
    ]
})

$('.slider-popup').slick({
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    // autoplay: true,
    prevArrow: $('.slider-popup-prev'),
    nextArrow: $('.slider-popup-next'),
    responsive: [
        {
            breakpoint: 992,
            settings: {
                dots: true,
                arrows: false,
            }
        },
    ]
})

if (window.screen.width < 992) {
    $('.slider-popup-nav').css('display', 'none');
}

const menuButton = document.querySelector('.menu-icon');
const bodyTag = document.querySelector('body');
const closeButton = document.querySelector('.close-icon');

menuButton.addEventListener('click', () => {
    bodyTag.classList.add('open')
})

closeButton.addEventListener('click', () => {
    bodyTag.classList.remove('open')
})

$(document).ready(function () {
    $('.header-menu__left a').hover(function () {
        let img = $(this).attr('data-img');
        $('.header-menu__right-bg').css('background-image', img)
    })

    if ($('.header-menu__left a').hasClass('active')) {
        let img = $(this).attr('data-img');
        $('.header-menu__right-bg').css('background-image', img)
    }
})

/* map */

const mapList = document.querySelectorAll('.place-map-list .place-map-list__item');

mapList.forEach(element => {
    element.addEventListener('click', () => {
        mapList.forEach(item => {
            item.classList.remove('active')
        })

        element.classList.add('active')
    })
});

let openFormBtn = document.querySelector('.js-open-form');
let openForm = document.querySelector('.popup');
let closeFormBtn = document.querySelector('.popup__close');
let popupOverlay = document.querySelector('.main-popup-form-overlay');

if (openFormBtn) {
    openFormBtn.addEventListener('click', () => {
        openForm.classList.add('open');
        popupOverlay.classList.add('open')
    })
}

if (closeFormBtn) {
    closeFormBtn.addEventListener('click', () => {
        openForm.classList.remove('open');
        popupOverlay.classList.remove('open')
    })
}

if (popupOverlay) {
    popupOverlay.addEventListener('click', () => {
        openForm.classList.remove('open');
        popupOverlay.classList.remove('open')
    })
}

$(document).on('click', '.popup__close', function() {
    $('.main-popup-form').removeClass('open');
    $('.main-popup-form-overlay').removeClass('open')
})

let arrayId = [
    document.getElementById('userName'),
    document.getElementById('company'),
    document.getElementById('phone'),
    document.getElementById('email')
]

if (document.querySelector('.form-send-button input') !== null) {
    document.querySelector('.form-send-button input').addEventListener('click', (e) => {
        const validateInput = (inputId) => {
            if (inputId.value == '') {
                e.preventDefault();
                inputId.classList.add('error')
            } else {
                inputId.classList.remove('error')
            }
        }

        [...arrayId].map(id => {
            validateInput(id);
        })
    })
}




let cookieBtn = document.querySelector('.js-cookie');
let cookieBanner = document.querySelector('.warning-cookie');

if (cookieBtn !== null) {
    cookieBtn.addEventListener('click', () => {
        if (cookieBanner.remove() !== null) {
            cookieBanner.remove();
        }
        document.cookie = 'okay'
    })
}

if (document.cookie === 'okay') {
    if (cookieBanner !== null) {
        cookieBanner.remove();
    }
}

/* resiz textarea */
var textarea = document.querySelector(".js-textarea");
var heightLimit = 500; /* Maximum height: 200px */

if (textarea !== null) {
    textarea.oninput = function() {
        textarea.style.height = ""; /* Reset the height*/
        textarea.style.height = Math.min(textarea.scrollHeight, heightLimit) + "px";
    };
}

/* parralax */

// Get all the elements to be parallaxed
const parallaxElements = document.querySelectorAll('.parallax')

// The parallax function
const parallax = elements => {
    if ('undefined' !== elements && elements.length > 0) {
        elements.forEach( element => {
            let y = window.innerHeight - element.getBoundingClientRect().top
            if (y > 0) {
                element.style.transform = 'translate3d(0, -' + (0.25 * y) + 'px ,0)'
            }
        })
    }
}

//If element is in viewport, set its position
parallax(parallaxElements)

//Call the function on scroll
window.onscroll = () => {
    parallax(parallaxElements)
}

// Функция ymaps.ready() будет вызвана, когда
// загрузятся все компоненты API, а также когда будет готово DOM-дерево.
ymaps.ready(init);

function init() {
    // Создание карты.
    var myMap = new ymaps.Map("map-place", {
        center: centerMap,
        zoom: 17,
        controls: ['zoomControl']
    });

    //на мобильных устройствах... (проверяем по userAgent браузера)
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        //... отключаем перетаскивание карты
        myMap.behaviors.disable('drag');
    }

    // создаем метки
    window.myObjects = ymaps.geoQuery({
        type: "FeatureCollection",
        features: dataMap,
    }).addToMap(myMap);


    // Объекты
    const objectList = document.querySelectorAll('.js-options-obj .place-map-filter-options__item');

    // Районы
    const objectArea = document.querySelectorAll('.js-options-area .place-map-filter-options__item');

    // Список улиц
    const placeList = document.querySelectorAll('.place-map-list .place-map-list__item');

    let showCards = (curReg, curType) => {
        
        placeList.forEach(item => {

            if (item.dataset.objName !== curReg && 'Все объекты' !== curReg) {
                item.style.display = "none";
            } else if (item.dataset.types !== curType && 'Все районы' !== curType) {
                item.style.display = "none";
            } else  {
                item.style.display = "block";
            }
        })
    }


    function checkState () {
        // создаем переменные
        let shownObjects,
            object = document.querySelector('.js-selected-obj').innerHTML,
            location = document.querySelector('.js-selected-area').innerHTML,
            filter_c = new ymaps.GeoQueryResult(),
            filter_l = new ymaps.GeoQueryResult();

        // проверяем с какими данными мы вообще работаем и в зависимости от этого убераем ненужные метки
            let variant = 0;

            if (object !== 'Все объекты') {
                variant += 10;
            }

            if (location !== 'Все районы') {
                variant += 100;

            }
            
            switch(variant) {
                case 0:
                    shownObjects = myObjects.addToMap(myMap);
                    showCards(object, location)
                    break;
                case 10:
                    filter_c = myObjects.search('options.object="'+ object +'"').add(filter_c);
                    shownObjects = filter_c.addToMap(myMap);
                    showCards(object, location)
                    break;
                case 100:
                    filter_l = myObjects.search('options.location="'+ location +'"').add(filter_l);
                    shownObjects = filter_l.addToMap(myMap);
                    showCards(object, location)
                    break;
                case 110:
                    filter_l = myObjects.search('options.location="'+ location +'"').add(filter_l);
                    filter_c = myObjects.search('options.object="'+ object +'"').add(filter_c);
                    shownObjects = filter_c.intersect(filter_l).addToMap(myMap);
                    showCards(object, location)
                    break;
            }
        
        // оставляем на карте только найденые метки
        myObjects.remove(shownObjects).removeFromMap(myMap);
    }

    // При клике на точку показать справа в списке
    myMap.geoObjects.events.add('click', function (e) {
        // Получение ссылки на дочерний объект, на котором произошло событие.
        var object = e.get('target');

        placeList.forEach(item => {

            if (object.properties._data.id === item.dataset.id) {
                item.classList.add('active');
            } else {
                item.classList.remove('active');
            }

        })
    });

    // При клике на список справа показать точку
    function clickAdressAndOpenBaloon() {
        placeList.forEach(item => {
            if (item.classList.contains('active')) {
                window.myObjects._objects.forEach(point => {
                    if (point.properties._data.id === this.dataset.id) {
                        
                        // Центрировать карту при клике на точку
                        myMap.setCenter([point.geometry._coordinates[0], point.geometry._coordinates[1]], 17, {
                            duration: 300
                        })

                        // Открыть balloon с задержкой
                        setTimeout(() => {
                            point.balloon.open();
                        }, 500)
                    }
                })
            }
        })
    }

    // Закрыть balloon если кликнули на карту
    myMap.events.add('click', e => e.get('target').balloon.close());

    objectList.forEach(item => {
        item.addEventListener('click', checkState)
    })

    objectArea.forEach(item => {
        item.addEventListener('click', checkState)
    })

    placeList.forEach(item => {
        item.addEventListener('click', clickAdressAndOpenBaloon)
    })
}

const buttonSelectOpt = document.querySelector('.js-selected-obj')
const optionsOpt = document.querySelector('.js-options-obj')
const buttonSelectOptList = document.querySelectorAll('.js-options-obj .place-map-filter-options__item')

if (buttonSelectOpt !== null) {
    buttonSelectOpt.addEventListener('click', () => {
        buttonSelectOpt.classList.toggle('open')
        optionsOpt.classList.toggle('open')

        buttonSelectArea.classList.remove('open')
        optionsArea.classList.remove('open')
    });

    buttonSelectOptList.forEach(item => {
        item.addEventListener('click', () => {
            buttonSelectOpt.classList.toggle('open')
            optionsOpt.classList.toggle('open')

            buttonSelectOptList.forEach(el => {
                el.classList.remove('active')
            })

            item.classList.add('active')

            // Берем дата атрибут из элемента списка и добавляем его в родительский для вывода
            buttonSelectOpt.innerHTML = item.innerHTML
            buttonSelectOpt.dataset.object = item.dataset.object
        })
    })
}

const buttonSelectArea = document.querySelector('.js-selected-area')
const optionsArea = document.querySelector('.js-options-area')
const buttonSelectAreaList = document.querySelectorAll('.js-options-area .place-map-filter-options__item')

if (buttonSelectArea !== null) {
    buttonSelectArea.addEventListener('click', () => {
        buttonSelectArea.classList.toggle('open')
        optionsArea.classList.toggle('open')

        buttonSelectOpt.classList.remove('open')
        optionsOpt.classList.remove('open')
    })

    buttonSelectAreaList.forEach(item => {
        item.addEventListener('click', () => {
            buttonSelectArea.classList.toggle('open')
            optionsArea.classList.toggle('open')

            buttonSelectAreaList.forEach(el => {
                el.classList.remove('active')
            })

            item.classList.add('active')

            // Берем дата атрибут из элемента списка и добавляем его в родительский для вывода
            buttonSelectArea.innerHTML = item.innerHTML
            buttonSelectArea.dataset.area = item.dataset.area
        })
    })
}

// проверка на корректность ввода
let phone = document.getElementById('phone'),
    email = document.getElementById('email');

if (phone !== null || email !== null) {

    let im = new Inputmask({
        "mask": "+7 (999) 999-99-99",
        showMaskOnHover: false,
    });

    let em = new Inputmask({
        "mask": "*{1,20}[.*{1,20}][.*{1,20}][.*{1,20}]@*{1,20}[.*{2,6}][.*{1,2}]",
        showMaskOnHover: false,
    })

    im.mask(phone);
    em.mask(email);
}